//import { keyframes } from '@angular/animations';
import {FormGroup} from '@angular/forms';
//import { stringify } from 'querystring';
//import { TableRequestBody } from '../models/local';

export default class Utilities {
  static splitArrayIntoChunks(array: any[], chunkSize: number): any[][] {
    const chunks = [];
    let i = 0;
    const n = array.length;

    while (i < n) {
      chunks.push(array.slice(i, i += chunkSize));
    }

    return chunks;
  }

  static isEmailAddress(email: string): boolean {
    // RegEx obtained from https://emailregex.com/ based on FRC 5322.
    const regexString = `(?:[a-z0-9!#$%&'*+/=?^_\`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_\`{|}~-]+)*|"`
      + `(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")`
      + `@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?`
      + `|\\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]`
      + `:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])`;
    const regex = new RegExp(regexString);
    return regex.test(email);
  }

  static getWhatsAppLink(phoneNumber: string, countryCode: string = '521'): string {
    return `https://wa.me/${countryCode}${phoneNumber}`;
  }

  static blobToFile = (blob: Blob, fileName: string): File => {
    return new File([blob], fileName);
  };

  static findFormInvalidMessage(formGroup: FormGroup, keysToCheck: { key: string, message: string }[]): string | void {
    formGroup.markAllAsTouched();
    for (let i = 0; i < keysToCheck.length; i++) {
      const formEl = formGroup.get(keysToCheck[i].key);
      if (formEl?.invalid) {
        return keysToCheck[i].message;
      }
    }
  }

  static getErrorFromLumenBackend(error: any) {
    return error.error ? error.error : error;
  }

  static getInternationalPhoneNumber(phoneNumber: string, country: string = 'MX') {
    switch (country) {
      case 'MX':
        return `+521${phoneNumber.trim()}`;
      default:
        return phoneNumber;
    }
  }

  static changeFileName(file: File, fileNumber: number, contentType: string = 'image/*'): File {
    const blob = file.slice(0, file.size, contentType);
    const name = `${file.name.split('.', 1)[0]}${fileNumber}.${file.name.split('.', 2)[1]}`;
    return new File([blob], name, {type: file.type});
  }

  static forceSecureUrl(url: string): string {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim()
      .replace(/\s/g, "")
      .replace(/^(http|ftp):\/\//, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `https${newUrl}`;
    }

    if (!/^https:\/\//i.test(newUrl)) {
      return `https://${newUrl}`;
    }

    return newUrl;
  }

  static arrayOfNumbersToString(numbers: number[]): string {
    let result: string = '';
    numbers.forEach(element => {
      result += element.toString();
    });

    return result;
  }

  static transformDatetime(date: Date | any) {
    return date.getFullYear().toString() + "-" +
      ((date.getMonth()+1).toString().length==2?(date.getMonth()+1).toString():"0"+(date.getMonth()+1).toString()) +
      "-" + (date.getDate().toString().length==2?date.getDate().toString():"0"+date.getDate().toString()) +
      " " + (date.getHours().toString().length==2?date.getHours().toString():"0"+date.getHours().toString()) +
      ":" + (((date.getMinutes()/5)*5).toString().length==2?((date.getMinutes()/5)*5).toString():"0" +
      ((date.getMinutes()/5)*5).toString())+":"+
      (((date.getSeconds()/5)*5).toString().length==2?((date.getSeconds()/5)*5).toString():"0" +
      ((date.getSeconds()/5)*5).toString());
  }

  static transformDate(date: Date | any) {
    return date.getFullYear().toString() + "-" +
      ((date.getMonth()+1).toString().length==2?(date.getMonth()+1).toString():"0"+(date.getMonth()+1).toString()) +
      "-" + (date.getDate().toString().length==2?date.getDate().toString():"0"+date.getDate().toString());
  }

  static transformDatetimeToISO(date: Date | any) {
    const tzoffset =date.getTimezoneOffset() * 60000;
    return (new Date(Date.parse(date) - tzoffset)).toISOString().slice(0, -1);
  }

  static transformObjectToFormData(object: any): FormData {
    const keys = Object.keys(object)
    let formData: FormData = new FormData;
    keys.forEach(key => {
      formData.append(key,object[key] || '');
    })
    return formData;
  }

  /**
   * Create URL to request a table
   */
 /* static createGetURLTableRequest(tableRQ: TableRequestBody, host: string, endPoint: string, functionId: string) {
    let requestURL =
      `${host}/${endPoint}?pageNum=${tableRQ.pageOffset}&perPage=${tableRQ.pageSize}`+
      `&functionObjectId=${functionId}`;
    if(tableRQ.filter)
      requestURL += `&filter=${tableRQ.filter}`;
    if(tableRQ.sortDir)
      requestURL += `&sortDir=${tableRQ.sortDir}`;
    if(tableRQ.sortField)
      requestURL += `&sortField=${tableRQ.sortField}`;
    if(tableRQ.isInitialTreatment != null)
    {
       let distributionType = tableRQ.isInitialTreatment ? 1 : 0;
       requestURL += `&isInitialTreatment=${distributionType}`;
    }

    return requestURL;
  }*/

  static removeKeysFromObjects(object: any): void {
    const keys = Object.keys(object)
    keys.forEach(key => {
      !object[key] ? delete object[key] : '';
    });
  }
}

