<div class="iks-layout__inner p-flex-column">


  <div class="laboratory-priority-modal" *ngIf="isDeleteModalOpen" (click)="closeDeleteModal()">
    <div class="laboratory-priority-container">

      <p>
        ¿Seguro que deseas eliminar el preenvío de {{selectedHistoryOrder?.medicineName}} para
        {{selectedHistoryOrder?.patientPublicName}}?
      </p>
      <div>
        <div>

          <div class="laboratories-container">
          </div>
          <div class="flex justify-content-center w-full pl-1 pr-1">
            <button pButton label="ELIMINAR" (click)="deletePreOrderApi()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_secondary iks-layout__btncontinue btn-continue">
            </button>
            <button pButton label="CANCELAR" (click)="closeDeleteModal()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_tertiary iks-layout__btncontinue btn-continue ml-4">
            </button>
          </div>

        </div>
      </div>


    </div>
  </div>

  <div class="laboratory-priority-modal" *ngIf="isSendModalOpen" (click)="closeSendModal()">
    <div class="laboratory-priority-container">

      <p>
        Estás a punto de enviar {{selectedHistoryOrder?.medicineName}} a {{selectedHistoryOrder?.patientPublicName}},
        ¿deseas continuar?
      </p>
      <div>
        <div>

          <div class="laboratories-container">
          </div>
          <div class="flex justify-content-center w-full pl-1 pr-1">
            <button pButton label="ENVIAR" (click)="updatePreInitialTreatmentsOrder()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_secondary iks-layout__btncontinue btn-continue">
            </button>
            <button pButton label="CANCELAR" (click)="closeSendModal()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_tertiary iks-layout__btncontinue btn-continue ml-4">
            </button>
          </div>

        </div>
      </div>


    </div>
  </div>

  <div class="laboratory-priority-modal" *ngIf="isSendAllModalOpen" (click)="closeSendAllModal()">
    <div class="laboratory-priority-container">

      <p>
        ¿Estás seguro que quieres enviar todos los medicamentos?
      </p>
      <div>
        <div>

          <div class="laboratories-container">
          </div>
          <div class="flex justify-content-center w-full pl-1 pr-1">
            <button pButton label="ENVIAR" (click)="updateAllPreInitialTreatmentsOrder()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_secondary iks-layout__btncontinue btn-continue">
            </button>
            <button pButton label="CANCELAR" (click)="closeSendAllModal()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_tertiary iks-layout__btncontinue btn-continue ml-4">
            </button>
          </div>

        </div>
      </div>


    </div>
  </div>

  <div class="laboratory-priority-modal" *ngIf="isResultModalOpen" (click)="closeResultModal()">
    <div class="laboratory-priority-container">

      <p>
        {{resultMessage}}
      </p>
      <div>
        <div>

          <div class="laboratories-container">
          </div>
          <div class="flex justify-content-center w-full pl-1 pr-1">
            <button pButton label="CONTINUAR" (click)="closeResultModal()" [disabled]="isSubmitting"
              class="p-button-rounded button_itx_secondary iks-layout__btncontinue btn-continue">
            </button>
          </div>

        </div>
      </div>


    </div>
  </div>








  <div class="iks-layout__container">
    <div class="grid">

      <div class="col-12 cart">
        <div *ngIf="showCart" class="cart__icon" (click)="goToCart()">
          <div class="cart__total" *ngIf="totalInCart > 0">{{totalInCart > 9 ? totalInCart : '0'+totalInCart}}</div>
          <img src="{{cartIconPath}}" height="30px" width="35px">
        </div>
      </div>

      <div class="col-12 mt-30">
        <label class="title__one">Lista de preenvíos</label>
      </div>

      <div class="col-12 mt-15" *ngIf="ordersToDisplay.length == 0 && isSubmitting == false">
        <label class="title__three secondary-color">Por el momento no tienes preenvíos</label>
      </div>

      <div>
        <div class="btn-fixed" *ngIf="quantityReleased > 0">
          <button pButton label="ENVIAR TODOS" (click)="openSendAllModal()"
            class="p-button-rounded button_itx_secondary iks-layout__btncontinue btn-continue" style="width: 300px;">
          </button>
        </div>

        <div class="col-12 grid" *ngFor="let historyOrder of ordersToDisplay">

          <!-- <div class="col-12 card">
            <div class="grid">
              <div class="col-7">
                <p class="text text__name">{{historyOrder.patientPublicName}}</p>
                <p class="text text__phoneNumber">{{historyOrder.patientPhoneNumber}}</p>
                <p class="text__date">{{historyOrder.created_at | date : 'd MMM y'}}</p>
              </div>
            </div>
          </div> -->




          <div class="card flex preorder-card-parent">
            <div class="preorder-card-section">
              <div class="mt-15">
                <div class="">
                  <div class="col-12 button button__quantity" *ngIf='!historyOrder.isPreInitialTreatment'>
                    LISTO PARA ENVIAR
                  </div>
                  <div class="col-12 button button__prerecord" *ngIf='historyOrder.isPreInitialTreatment'>
                    PRÓXIMAMENTE
                  </div>
                  <div class="col-1">
                  </div>
                </div>
              </div>

              <div class="">
                <p class="text text__name card-elipsis">{{historyOrder.patientPublicName}}</p>
                <p class="text text__phoneNumber card-elipsis">{{historyOrder.patientPhoneNumber}}</p>
                <p class="text text__phoneNumber card-elipsis">{{historyOrder.medicineName}}</p>
                <p class="text__date card-elipsis">{{historyOrder.created_at | date : 'd MMM y'}}</p>
              </div>
            </div>

            <div class="full-height ml-8">
              <div class="flex full-height">
                <div class="flex flex-center">
                  <img src="{{historyOrder.medicinePicture}}" class="img__medicine">
                </div>
                <div class="col-2 icon__align flex flex-center ml-4 flex-col">
                  <img src="{{'../../assets/initial-treatment/Tratamiento_enviado.png'}}"
                    class="icon icon__check-favorite" *ngIf="!historyOrder.isPreInitialTreatment"
                    (click)="openSendModal(historyOrder)">
                  <img src="{{'../../assets/initial-treatment/Basura.png'}}" class="icon icon__check-favorite basura"
                    (click)="openDeleteModal(historyOrder)">
                </div>
              </div>
            </div>


          </div>




        </div>

        <br><br><br><br><br><br><br><br><br><br>
      </div>

    </div>
  </div>

  <div class="iks-layout__sidebar-right">
    <div class="grid">

    </div>
  </div>

</div>