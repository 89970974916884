// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectData: {
    host: 'https://itxappweb.iknesoft.in/',
    termsConditions: 'https://static.iniciotx.com/terminos-y-condiciones/',
    privacyPolicy: 'https://static.iniciotx.com/aviso-de-privacidad/',
    supportEmail: 'soporteaclientes@iniciotx.com'
  },
  api: {
    // host: 'https://itxbackdev.iknesoft.in',
    // IKShost: 'https://itxback.iniciotx.com',
    // host: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/',
    // IKShost: 'https://hvcqr3uo58.execute-api.us-east-1.amazonaws.com/',
    // host: 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com/',
    // IKShost: 'https://mtwt6z469a.execute-api.us-east-1.amazonaws.com/',
    //  host: 'http://localhost:60225/api/',
    //  IKShost: 'http://localhost:60225/api/'
    // host: 'https://bfa5-2806-2f0-4460-17e-42a8-ef4b-d549-6b56.ngrok-free.app/api/',
    // IKShost: 'https://bfa5-2806-2f0-4460-17e-42a8-ef4b-d549-6b56.ngrok-free.app/api/'
      host: 'https://iniciotxdevelop.azurewebsites.net/api',
      IKShost: 'https://iniciotxdevelop.azurewebsites.net/api'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
