import { Injectable } from '@angular/core';
import { Medicine } from 'src/app/models/remote/medicine';
import { DISTRIBUTION_TYPES } from '../../constants';

@Injectable({
  providedIn: 'root'
})
export class MedicineCartService {

  //medicines : Map<string, Medicine> = new Map<string, Medicine>();

  medicinesCart: Medicine[] = [];

  constructor() { }

  public checkExistMedicine(medicineObjectId: string): Medicine | undefined {
    return this.medicinesCart.find(item => item.objectId === medicineObjectId);
  }

  public addToCart(medicine: Medicine): void {
    let medicineItem = this.checkExistMedicine(medicine.objectId!);
    if(medicineItem) {
      medicineItem.quantityInCart! += 1;
    }
    else {
      medicine.quantityInCart! = 1;
      this.medicinesCart.push(medicine);
    }
  }

  public removeToCart(medicine: Medicine): void {
    let medicineItem = this.checkExistMedicine(medicine.objectId!);
    if(medicineItem) {
      medicineItem.quantityInCart! -= 1;
      if(medicineItem.quantityInCart == 0) {
        this.medicinesCart = this.medicinesCart.filter(item => item.objectId != medicine.objectId);
      }
    }
  }

  public getTotalInCart(): number {
    return this.medicinesCart.length;
  }

  public getMedicinesCart(): Medicine[] {
    return this.medicinesCart;
  }

  public getMedicineTypeInCart(): string {
    if(this.medicinesCart.length > 0) {
      if(this.medicinesCart[0].distributionTypeObjectId == DISTRIBUTION_TYPES.initialTreatment)
        return DISTRIBUTION_TYPES.initialTreatment;
      return DISTRIBUTION_TYPES.medicalSample;
    }
    return ''
  }

  public cleanCart(): void {
    this.medicinesCart = [];
  }

}
