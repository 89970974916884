import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { DOCTOR_FUNCTIONS } from 'src/app/constants';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  constructor(private httpClient: HttpClient) { }

  public getOrdersHistory(pageNum: number, perPage: number) {
    let params =  new HttpParams;
    params = params.append('pageNum', pageNum);
    params = params.append('perPage', perPage);
    params = params.append('functionObjectId', DOCTOR_FUNCTIONS.getOrdersHistory);
    return this.httpClient.get<any>(`${AppSettings.orderHost}/getOrdersHistory`, {params}).toPromise();
  }

}
