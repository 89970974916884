import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthService } from '../../services/auth/auth.service';
import { HelperService } from '../../services/project/helper.service';



@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivateChild {

  constructor(public authService: AuthService, private router: Router, private helperService: HelperService) {
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      return this.authService.isAuthenticated().then(isAuthenticated => {
        if (isAuthenticated) {
          return true;
        }

        this.helperService.showMessage('error', '', 'Sesión no válida. Inicia sesión de nuevo.')
        this.router.navigate(['/public'], { replaceUrl: true });
        return false;
      });
  }

}
