import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { MessageService } from 'primeng/api';
import { HistoryService } from '../services/data/history.service';
import { HistoryOrder } from '../models/remote/history-order';
import { MedicineCartService } from '../services/data/medicine-cart.service';
import { DISTRIBUTION_TYPES } from '../constants';
import { INITIAL_TREATMENT_STATUSES, MEDICAL_SAMPLE_STATUSES } from '../constants';
import { AppSettings } from '../app.settings';
import Utilities from '../helpers/utilities';
import { PreorderService } from '../services/data/preorder.service';

@Component({
  selector: 'app-history-preorders',
  templateUrl: './history-preorders.component.html',
  styleUrls: ['./history-preorders.component.scss']
})
export class HistoryPreordersComponent implements OnInit {


  ordersToDisplay: HistoryOrder[] = [];
  selectedHistoryOrder?: HistoryOrder;
  initialTreatmentType: string =  'Initial Treatment';
  medicalSampleType: string = 'Medical Sample';
  totalInCart: number = 0;
  cartIconPath: string = '../../assets/icons/cart_gray.png'

  pageNum: number = 0;
  perPage: number = 1000;

  isSubmitting: boolean = false;

  showCart: boolean = false;
  hasInitialTreatments: boolean = false;
  hasMedicalSamples: boolean = false;
  quantityReleased: number = 0;

  isSendModalOpen: boolean = false;
  isSendAllModalOpen: boolean = false;
  isDeleteModalOpen: boolean = false;
  isResultModalOpen: boolean = false;
  resultMessage: string = '';


  constructor(private router: Router,
              private historyService: HistoryService,
              private preordersService: PreorderService,
              private medicineCartService: MedicineCartService,
              private messageService: MessageService) {
  }

  ngOnInit(): void {
    this.setShowCart();
    this.getTotalInCart();
    this.getPreOrdersHistory();
    this.validateUpdatePreInitialTreatments();
  }

  public openSendAllModal(): void {
    this.isSendAllModalOpen = true;
  }
  public openResultModal(): void {
    this.isResultModalOpen = true;
  }
  public openSendModal(historyOrder: HistoryOrder): void {
    this.selectedHistoryOrder = historyOrder
    this.isSendModalOpen = true
  }
  public openDeleteModal(historyOrder: HistoryOrder): void {
    this.selectedHistoryOrder = historyOrder
    this.isDeleteModalOpen = true
  }

  public closeSendAllModal(): void {
    this.isSendAllModalOpen = false
  }
  public closeResultModal(): void {
    this.isResultModalOpen = false
    window.location.reload()
  }
  public closeSendModal(): void {
    this.selectedHistoryOrder = undefined
    this.isSendModalOpen = false
  }
  public closeDeleteModal(): void {
    this.selectedHistoryOrder = undefined
    this.isDeleteModalOpen = false
  }

  public validateUpdatePreInitialTreatments(): void {
    this.isSubmitting = true;
    this.preordersService.validateUpdatePreInitialTreatments().then(response => {
      this.quantityReleased = response?.quantityReleased || 0
      this.isSubmitting = false;
    }).catch(error => {
      this.isSubmitting = false;
      error = Utilities.getErrorFromLumenBackend(error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
    });
  }

  public updatePreInitialTreatmentsOrder(): void {
    if (!this.selectedHistoryOrder) return
    this.isSubmitting = true;
    const body = {
      preInitialTreatmentIds: [this.selectedHistoryOrder.objectId]
    }
    this.preordersService.updatePreInitialTreatmentsOrder(body).then(response => {
      this.closeSendModal()
      this.openResultModal()
      this.resultMessage = response?.message
      this.isSubmitting = false;
    }).catch(error => {
      this.isSubmitting = false;
      error = Utilities.getErrorFromLumenBackend(error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      // this.messageService.add({
      //   key: 'app-main-toast',
      //   severity: 'error',
      //   summary: 'Inválido',
      //   detail: message,
      //   closable: false
      // });
      this.closeSendModal()
      this.openResultModal()
      this.resultMessage = message
    });
  }

  public deletePreOrderApi(): void {
    if (!this.selectedHistoryOrder) return
    this.isSubmitting = true;
    const body = {
      preInitialTreatmentsOrderObjectId: this.selectedHistoryOrder.objectId
    }
    this.preordersService.deletePreOrderApi(body).then(response => {
      this.closeDeleteModal()
      this.openResultModal()
      this.resultMessage = response?.message
      this.isSubmitting = false;
    }).catch(error => {
      this.isSubmitting = false;
      error = Utilities.getErrorFromLumenBackend(error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      // this.messageService.add({
      //   key: 'app-main-toast',
      //   severity: 'error',
      //   summary: 'Inválido',
      //   detail: message,
      //   closable: false
      // });
      this.closeDeleteModal()
      this.openResultModal()
      this.resultMessage = message
    });
  }

  public updateAllPreInitialTreatmentsOrder(): void {
    this.isSubmitting = true;
    const body = {
      preInitialTreatmentIds: [],
      sendAll: true
    }
    this.preordersService.updatePreInitialTreatmentsOrder(body).then(response => {
      this.closeSendAllModal()
      this.openResultModal()
      this.resultMessage = response?.message
      this.isSubmitting = false;
    }).catch(error => {
      this.isSubmitting = false;
      error = Utilities.getErrorFromLumenBackend(error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      // this.messageService.add({
      //   key: 'app-main-toast',
      //   severity: 'error',
      //   summary: 'Inválido',
      //   detail: message,
      //   closable: false
      // });
      this.closeSendAllModal()
      this.openResultModal()
      this.resultMessage = message
    });
  }

  public setShowCart(): void {
    this.hasInitialTreatments = localStorage.getItem(AppSettings.itxPermissionPrefix) == 'true' ? true : false;
    this.hasMedicalSamples = localStorage.getItem(AppSettings.mmPermissionPrefix) == 'true' ? true : false;
    if(this.hasInitialTreatments || this.hasMedicalSamples)
      this.showCart = true;
  }

  public getTotalInCart(): void {
    this.totalInCart = this.medicineCartService.getTotalInCart();
    if(this.totalInCart > 0)
      this.cartIconPath = '../../assets/icons/cart_purple.png';
  }

  public getPreOrdersHistory() {
    this.isSubmitting = true;
    this.preordersService.getPreOrdersByDoctor({pageNum: this.pageNum, perPage: this.perPage}).then(response => {
      this.ordersToDisplay = response.data;
      this.isSubmitting = false;
    }).catch(error => {
      this.isSubmitting = false;
      error = Utilities.getErrorFromLumenBackend(error);
      let message = error.message || 'Algo salió mal. Intenta de nuevo.';
      this.messageService.add({
        key: 'app-main-toast',
        severity: 'error',
        summary: 'Inválido',
        detail: message,
        closable: false
      });
    });
  }

  public goToOrderDetail(orderObjectId: string, orderType: string): void {
    if(orderType == this.initialTreatmentType)
      this.router.navigate([`/auth/history/initial-treatment-detail/${orderObjectId}`]);
    else if(orderType == this.medicalSampleType)
      this.router.navigate([`/auth/history/medical-sample-detail/${orderObjectId}`]);
  }

  public getIconPathAlt(acceptedByPatientDate: string, receivedByPatientDate: string) {
    if (receivedByPatientDate)
    return '../../assets/initial-treatment/started_celeste.png';

    if (acceptedByPatientDate)
    return '../../assets/initial-treatment/accepted_blue.png';

    return '../../assets/initial-treatment/sent_aqua.png';
  }

  public getIconPath(statusId: string, orderType: string): string {
    if(orderType == this.initialTreatmentType) {
      if(statusId == INITIAL_TREATMENT_STATUSES.treatmentStarted || statusId == INITIAL_TREATMENT_STATUSES.noAcceptedByPatient)
        return '../../assets/initial-treatment/started_celeste.png';
      else if(statusId == INITIAL_TREATMENT_STATUSES.acceptedByPatient)
        return '../../assets/initial-treatment/accepted_blue.png';
      else
        return '../../assets/initial-treatment/sent_aqua.png';
    }
    else if(orderType == this.medicalSampleType) {
      if(statusId == MEDICAL_SAMPLE_STATUSES.sent || statusId == MEDICAL_SAMPLE_STATUSES.expired)
        return '../../assets/medical-sample/sent_celeste.png';
      else
        return '../../assets/medical-sample/attended_aqua.png';
    }
    return '../../assets/initial-treatment/started_celeste.png';
  }

  public goToCart(): void {
    let medicineTypeInCart = this.medicineCartService.getMedicineTypeInCart();

    if(this.hasInitialTreatments && this.hasMedicalSamples) {
      if(medicineTypeInCart == DISTRIBUTION_TYPES.medicalSample)
        this.router.navigate([`/auth/medical-samples/cart`]);
      else
        this.router.navigate([`/auth/initial-treatments/cart`]);
    }
    if(this.hasInitialTreatments)
      this.router.navigate([`/auth/initial-treatments/cart`]);
    if(this.hasMedicalSamples)
      this.router.navigate([`/auth/medical-samples/cart`]);

  }

}
