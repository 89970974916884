import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User, UserFunction } from 'src/app/models/remote/user';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private requestUrl: string = environment.api.host;
  private userRequestURL: string = AppSettings.userHost;
  private userToken: string | null = '';
  private currentUser: User | null = null;

  options = {
    headers: new HttpHeaders({'Content-Type': 'application/json'}),
    withCredentials: true
  }

  constructor(private httpClient: HttpClient) {
  }

  private processUserResponse(user: User): Promise<User | any> {
    try {
      localStorage.setItem(AppSettings.authTokenLocalStorageKey, JSON.stringify(user));
      this.userToken = localStorage.getItem(AppSettings.authTokenLocalStorageKey);
      this.userToken = user['token'];
      //delete user['token'];
      this.currentUser = user;
      return Promise.resolve(user);
    } catch (error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  private invokeLogin(identity: string, password: string): Promise<User | any> {
    return this.httpClient.post<any>(`${this.requestUrl}/login?email=${identity}&password=${password}`, this.options).toPromise();
  }

  public login(identity: string, password: string): Promise<User | any> {
    return this.invokeLogin(identity, password).then((user: User) => {
      return this.processUserResponse(user);
    }).catch(error => {
      throw error;
    });
  }

  public async logout(): Promise<void> {
    this.userToken = '';
    this.currentUser = null;
    if (this.getLocalUserToken()) {
        localStorage.removeItem(AppSettings.authTokenLocalStorageKey);
        localStorage.removeItem(AppSettings.mmPermissionPrefix);
        localStorage.removeItem(AppSettings.itxPermissionPrefix);
        localStorage.removeItem(AppSettings.tutorialPrefix);
        return await firstValueFrom(this.httpClient.put<any>(`${this.userRequestURL}/logout`, this.options));
    }
  }

  private async invokeIsAuthenticated(): Promise<boolean> {
    return await firstValueFrom(this.httpClient.get<boolean>(`${this.requestUrl}/isAuthenticated`));
  }

  public isAuthenticated(): Promise<boolean> {
    return this.invokeIsAuthenticated().then(isTokenValid => {
      if(!isTokenValid)
        this.logout()
      return isTokenValid;
    }).catch(error => {
      return false
    })
  }

  public getLocalUserToken() {
    try {
      let localData: any;
      if (!this.userToken && localStorage.getItem(AppSettings.authTokenLocalStorageKey)) {
          localData = JSON.parse(localStorage.getItem(AppSettings.authTokenLocalStorageKey)!);
          this.userToken = localData.token;
      }
      return this.userToken;
    } catch(error) {
      throw {message: 'Tu navegador tiene las cookies deshabilitadas. Asegúrate de tener las cookies habilitadas y prueba de nuevo.'}
    }
  }

  public hasStudies(): Promise<any> {
    return this.httpClient.get<any>(`${AppSettings.userHost}/hasStudies`)
    .toPromise()
  }


  public getUserFunctions(): Promise<UserFunction[] | any> {
    return this.httpClient.get<UserFunction[]>(`${AppSettings.userHost}/getFunctions`).toPromise();
  }

  public getRecoveryPasswordCode(dataToRecover: string, option: string): Promise<any> {
    const requestBody = {
      dataToRecover: dataToRecover,
      option: option
    }
    return this.httpClient.post<any>(`${this.requestUrl}/recoveryPasswordCode`, requestBody).toPromise();
  }

  public verifyResetPasswordCode(recoveryCode: string): Promise<any> {
    return this.httpClient.post<any>(`${this.requestUrl}/validateRecoveryCode`, {recoveryCode}).toPromise();
  }

  public resetPassword(recoveryCode: string, newPassword: string): Promise<string> {
    return this.httpClient.post<any>(`${this.requestUrl}/resetPasswordByCode`, {recoveryCode, newPassword}).toPromise();
  }

}
