import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app/app.settings';
import { DOCTOR_FUNCTIONS, dotNetUrlApi } from 'src/app/constants';
import { RequestBody } from 'src/app/models/local';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PreorderService {
    private dotNetPreOrderApi = `${dotNetUrlApi}/preOrder`

    onlyFavorites: boolean =  false;

    constructor(private httpClient: HttpClient) { }

    public updatePreInitialTreatmentsOrder(requestBody: any): Promise<any> {
      return this.httpClient.post<any>(`${this.dotNetPreOrderApi}/updatePreInitialTreatmentsOrder`, requestBody).toPromise();
    }

    public deletePreOrderApi(requestBody: any): Promise<any> {
      return this.httpClient.post<any>(`${this.dotNetPreOrderApi}/deletePreOrder`, requestBody).toPromise();
    }

    public getPreOrdersByDoctor(requestBody: any): Promise<any> {
      let params =  new HttpParams({ fromObject: requestBody });
      return this.httpClient.get<any>(`${this.dotNetPreOrderApi}/getPreOrdersByDoctor`, {params}).toPromise();
    }

    public getPreOrderById(requestBody: any): Promise<any> {
      let params =  new HttpParams({ fromObject: requestBody });
      return this.httpClient.get<any>(`${this.dotNetPreOrderApi}/getPreOrderById`, {params}).toPromise();
    }

    public validateUpdatePreInitialTreatments(): Promise<any> {
      return this.httpClient.get<any>(`${this.dotNetPreOrderApi}/validateUpdatePreInitialTreatments`).toPromise();
    }
    public getPublicPreOrdersByDoctor(requestBody: any): Promise<any> {
      let params =  new HttpParams({ fromObject: requestBody });
      return this.httpClient.get<any>(`${this.dotNetPreOrderApi}/getPublicPreOrdersByDoctor`, {params}).toPromise();
    }
    public updatePublicPreInitialTreatmentsOrder(requestBody: any): Promise<any> {
      return this.httpClient.post<any>(`${this.dotNetPreOrderApi}/updatePublicPreInitialTreatmentsOrder`, requestBody).toPromise();
    }
    public validatePublicUpdatePreInitialTreatments(token: string): Promise<any> {
      return this.httpClient.get<any>(`${this.dotNetPreOrderApi}/validatePublicUpdatePreInitialTreatments?token=${token}`).toPromise();
    }
    
}
